.information-wrapper{
    display: flex;
    justify-content: space-between;
    color: #ccc;
}

.dropzone-info{
    cursor: pointer;
}
.progressBarContainer {
    width: 100%;
    background-color: #e0e0e0;
    margin-top: 10px;
    height: 20px;
    border-radius: 10px;
    overflow: hidden;
  }

  .progressBar {
    height: 100%;
    background-color: #4CAF50;
  }