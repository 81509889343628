.header {
    background-color: #fff;
    padding: 15px 0;
    margin-bottom: 40px;
  }

  .header-logo img{
    height: 100px;
  }

.container-header{
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.right-header{
  display: flex;
}

.contact-box{
  background-color: rgba(198, 229, 213, 0.33) ;
  margin-right: 5px;
  color: #043528;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}
.contact-box a{
  color:#043528;
  font-weight: bold;
}

.contact-box_icon img{
  height: 50px;
  display: flex;
}


.contact-box_text{
  padding: 0 10px;
  display: flex;
  flex-direction: column;
}

.buttons-wrapper{
  position: relative;
}
.buttons-wrapper button, .contact-box_icon{
  border: none;
  background:none;
  color: #043528;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 100%;
  padding: 25px 10px;
}

.password-button{
  border: none;
  background-color: rgba(198, 229, 213, 0.33) ;
  color: #043528;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  margin-right: 5px;
}

.buttons-wrapper button img{
  height: 41px;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  z-index: 10;
  width: 140px;
}

.dropdown-menu button {
  display: block;
  width: 100%;
  text-align: left;
  padding: 5px;
  border: none;
  background: none;
  transition: 300ms;
}

.dropdown-menu button:hover {
  background-color: rgba(198, 229, 213, 0.33);
}



@media (max-width: 600px) {
  .header-logo img{
    height: 50px;
  }
  .contact-box{
    margin-right: 0;
    width: initial;
    font-size: 12px;
  }
  .right-header{
    flex-direction: row;
    text-align: center;
  }
  .contact-box_icon{
    display: none;
  }
}