.breadcrumbs {
    display: flex;
    flex-direction: row;
    gap: 5px;
    flex:1 1 50%
  }
  .breadcrumbs .breadcrumb{
    display: flex;
    align-items: center;
  }

  .breadcrumbs .breadcrumb a {
    text-decoration: none;
    color: #000;
  }

  .breadcrumbs .breadcrumb::after {
    content: ">";
    margin-left: 5px;
  }

  .breadcrumbs .breadcrumb:last-child::after {
    content: "";
  }

  .skeleton {
    background: #ffffff;
    border-radius: 4px;
    height: 24px;
    width: 120px;
    animation: loading 1.5s infinite;
  }

  @keyframes loading {
    0% {
      background: #ffffff;
    }
    50% {
      background: #cccccc;
    }
    100% {
      background: #ffffff;
    }
  }