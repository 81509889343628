.projectListItem {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  text-decoration: none;
  transition: 0.3s;
  border-radius: 6px;
  border: 1px solid #0000001a;
  color: #043528;
  max-width: 222px;

  &:hover {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
  }

  .imageContainer {
    min-width: 222px;
    min-height: 240px;
    background-color: #d9d9d980;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 38px;
    font-weight: 400;

    .project-name {
      font-size: 28px;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      line-clamp: 4;
      -webkit-box-orient: vertical;
      word-break: break-word;
      padding: 0 10px;
    }
  }

  .aboutContainer {
    padding: 12px;
    min-height: 80px;
    max-height: 80px;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .client-name {
      font-size: 16px;
      line-height: 24px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

@media (max-width: 600px) {
  .projectListItem {
    min-width: initial;
  }
}
