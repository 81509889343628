.footer-container{
    z-index: 1;
    display: block;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
}

.footer-bottom{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-width: 100%;
    border-top: 1px solid #003629;
    padding: 20px 0;
}

.footer-link-single{
    display: inline-block;
    margin-left: 20px;
    margin-right: 20px;
    padding: 0;
    border: 1px #000;
    background-color: transparent;
    transition: transform 250ms cubic-bezier(.215, .61, .355, 1);
    color: #000;
    text-decoration: none;
}

.footer-link-single:hover{
    transform: translate(8px,0);
}

.socials-list{
    display: flex;
    justify-content: flex-end;
}

.social-item{
    display: flex;
    width: auto;
    padding-right: 25px;
    padding-left: 0;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
}