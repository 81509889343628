.hamburger {
    position: absolute;
    left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 2rem;
    height: 2rem;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 10;
    outline: none;
  }

  .hamburgerDiv {
    width: 2rem;
    height: 0.25rem;
    background: #000000;
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
  }

  .openHamburgerDiv {
    background: #0D0C1D;
  }

  .rotate45 {
    transform: rotate(45deg);
  }

  .translate20px {
    opacity: 0;
    transform: translateX(20px);
  }

  .rotateMinus45 {
    transform: rotate(-45deg);
  }




