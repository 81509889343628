.infoBoxWrapper {
  align-self: center;
  width: 225px;
  display: block;
  height: 190px;
  position: relative;
  @media (max-width: 1024px) {
    width: 190px;
  }

  .infoBox {
    position: absolute;
    background-color: #c6e5d5;
    border-radius: 8px;
    color: #043528;
    padding: 35px 19px 17px;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    margin-top: 20px;

    @media (max-height: 700px) {
      padding: 20px 19px 6px;
    }

    .infoBoxTitle {
      padding-bottom: 20px;
      @media (max-height: 700px) {
        padding-bottom: 10px;
      }
    }

    .infoBoxButton {
      width: 100%;
      background-color: #043528;
      border-radius: 8px;
      color: #c6e5d5;
      padding: 17px 19px;
      font-size: 14px;
      font-weight: 400;
      border: none;
      display: block;
      text-decoration: none;
      cursor: pointer;
      text-align: center;
      @media (max-height: 700px) {
        padding: 10px 19px;
      }
    }

    .iconContainer {
      background-color: #fffce5;
      position: absolute;
      bottom: 100%;
      left: 50%;
      transform: translate(-50%, +50%);
      border-radius: 50%;
      padding: 6px;
      display: flex;
    }
  }
}
