.breadcrumbs-wrapper{
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  align-items: center;
  flex-direction: row;
  align-items: center;
  padding-bottom: 20px;
}
.navbar {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 20px 40px;
    justify-content: space-between;
    align-items: center;
    color: #043528;
    border-radius: 12px;
    background-color: rgba(255, 252, 229, 1);
    transition: height 0.3s ease;
  }

  .nav-item {
    text-decoration: none;
    color: #043528;
    padding: 5px 0;
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .line{
    width: 1px;
    height: 25px;
    background: #043528;
  }

  .navbar.hide {
    height: 0;
    overflow: hidden;
    padding: 0;
  }

  .nav-item:hover::after,
    .nav-item.active::after{
      content: '';
      display: block;
      width: 40%;
      height: 2px;
      background: #043528;
      position: absolute;
      left: 30%;
      transform: scaleX(1);
      top: 100%;
  }

  .hamburger {
    display: none;
  }

  .link{
    text-decoration: none;
    color:#000000;
    flex:1 1 50%;
  }
  @media (max-width: 600px) {
    .navbar {
      flex-direction: column;
      width: initial;
    }
    .line{
      display: none;
    }
    .hamburger {
      display: block;
      margin-bottom: 20px;
    }

  }