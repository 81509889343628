.projectListItem {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    padding: 0 5px;
    border-radius: 10px;
    color: #043528;
    text-decoration: none;
    box-sizing: border-box;
    border: 1px solid #043528;
    background-color: #C6E5D5;
    transition: 0.3s;

    &:nth-child(2n) {
      background-color: #FFFFFF;
    }

    &:hover {
      -webkit-transform: translateY(-2px);
      transform: translateY(-2px);
    }

    a {
      text-decoration: none;
    }
  }

  .section {
    flex: 1 1 50%;
    display: flex;
    align-items: center;
    overflow: hidden;
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }

  .templateItem {
    a {
      text-decoration: none;
    }

    &:hover {
      -webkit-transform: translateY(-2px);
      transform: translateY(-2px);
    }
  }

  .folderIcon, .informationIcon {
    width: 38px;
    height: 38px;
    margin-right: 10px;
  }

  .informationIcon {
    width: 32px;
    height: 32px;
  }

  .client-name, .project-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    display: block;

    &.client-name {
      font-size: 22px;
      font-weight: 400;
    }

    &.project-name {
      font-size: 24px;
      font-weight: 500;
    }
  }
