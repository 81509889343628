.list-container {
  display: flex;
  flex-direction: column;
  list-style: none;
  width: 100%;
  padding: 0;
  margin-bottom: 100px;
}

.list-header {
  display: flex;
  padding: 10px;
  flex: 1 1 auto;
  gap:30px
}

.list-header .photo{
  width: 90px;
}

.list-item {
  display: flex;
  padding: 10px;
  border-bottom: 1px dashed #ccc;
  flex: 1 1 auto;
  flex-wrap: wrap;
  gap:20px
}

.left-side,
.right-side {
  display: flex;
  align-items: center;
}

.left-side {
  flex-grow: 1;
  flex-basis: 0;
  justify-content: flex-start;
  gap:20px
}

.right-side {
  flex-grow: 0;
  flex-basis: 35%;
  justify-content: space-between;
}

.comment-button, .download-button{
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 12px;
  cursor: pointer;
  transition: 300ms ease-in-out;
}

.comment-button.template{
  cursor: initial;
  background-color: #d2d2d2;
}

.comment-button:hover, .download-button:hover{
  background-color: #d2d2d2;
}

.file-title button {
  font-weight: bold;
  background: none;
  border: none;
  padding: 0;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  font-size: inherit;
  font-family: inherit;
}


.file-picture {
  border: 1px solid #ccc;
  border-radius: 12px;
  max-width: 90px;
  max-height: 60px;
}

.file-subinfo {
  color: #cccccc;
  font-weight: 400;
}
.comment-section {
  font-size: 14px;
  color: #666;
  border: 1px solid #ccc;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 4px;
  width: 100%;
}


@media (max-width: 600px) {
  .list-item {
    justify-content: center;
  }
  .right-side {
    flex-basis: 35%;
  }
  .list-header {
    display: none;
  }
  .list-container {
    margin-bottom: 50px;
  }

}