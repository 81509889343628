.template-header-container {
  margin: 0 auto;
  padding: 5px 0 40px;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;
  min-height: 90px;
}

.projectList {
  display: flex;
  gap: 30px;
  margin-bottom: 20px;
  border-radius: 10px;
  padding: 10px;
  background: rgba(198, 229, 213, 0.33);

  &.list {
    flex-direction: column;
    gap: 20px;
  }

  &.box {
    display: grid;
    grid-template-columns: repeat(auto-fill, 222px);
    grid-gap: 1rem;
    justify-content: space-between;

    .item {
      flex: 0 0 calc(33.333% - 20px);
    }
  }
}

.wrapper {
  margin: 0 auto;
  padding: 0 15px;
  width: 100%;

  p {
    color: #043528;
    font-size: 14px;

    @media (max-width: 600px) {
      width: 100%;
    }
  }
}

.tableHeader {
  display: flex;
  padding-bottom: 10px;
}

.tableTitleWrapper {
  flex: 1 1 55%;
}

.tableOptionsWrapper {
  display: flex;
  align-items: flex-end;
  flex: 1 1 45%;
}

.options {
  display: flex;
  align-items: center;
  flex: 1 1;
}

.tableTitle {
  font-size: 40px;
  font-weight: 700;
  color: #043528;
}

.tableOptionButton {
  flex: 1 1 10%;
}

.toggleView {
  background-color: #ffffff;
  padding: 0 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;

  img {
    height: 41px;
  }
}

.noItemsMessage {
  color: #043528;
  background-color: #c6e5d5;
  border: 1px solid #043528;
  border-radius: 10px;
  padding: 25px 10px;
  grid-column: 1/-1;
}

@media (max-width: 768px) {
  .projectList.box .item {
    flex: 0 0 calc(50% - 20px);
  }
}

@media (max-width: 480px) {
  .projectList.box .item {
    flex: 0 0 100%;
  }
}
