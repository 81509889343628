.searchWrapper{
    flex:1 1 90%;
}

.searchBar {
    padding: 8px 30px 8px 8px;
    border-radius: 6px;
    border: 1px solid #ccc;
    width: 100%;
}

.searchBar:focus{
    outline: none;
}

.searchContainer {
    position: relative;
    display: inline-block;
    width: 100%;
}

.searchIcon {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
}
