.header-title {
    text-align: left;
    font-size: 24px;
  }

  .skeleton {
    background: #ffffff;
    border-radius: 4px;
    height: 36px;
    width: 250px;
    animation: loading 1.5s infinite;
  }

  @keyframes loading {
    0% {
      background: #ffffff;
    }
    50% {
      background: #cccccc;
    }
    100% {
      background: #ffffff;
    }
  }

  @media (max-width: 600px) {
    .header-title {
      margin-bottom: 10px;
      font-size: 16px;
    }


}