.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.42);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
}

.modalContent {
    width: 40vw;
    min-height: 35vh;
    background-color: #fff;
    padding: 30px;
    border-radius: 20px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
}

.titleButtonWrapper {
    display: flex;
    justify-content: space-between;
}

.closeButton {
    font-size: 24px;
    cursor: pointer;
}

.modalBody {
    /* Additional styling for modal body if needed. */
}

.buttonsWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
}

.modalButton {
    padding: 8px 20px;
    margin: 10px;
    border: none;
    border-radius: 20px;
    font-size: 14px;
    cursor: pointer;
}

.modalButtonSave {
    background-color: #000;
    color: #fff;
    border: 1px solid #000;
}

.modalButtonCancel {
    background-color: #fff;
    color: #000;
    border: 1px solid #ccc;
}

@media (max-width: 600px) {
    .modalContent {
        width: 90vw;
    }
}
