.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.input-wrapper {
    display: flex;
    flex-direction: column;
    font-size: 32px;
    width: 100%;
}

.input {
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.button {
    padding: 8px 20px;
    margin: 10px;
    border: none;
    border-radius: 20px;
    font-size: 14px;
    cursor: pointer;
    background-color: #ffffff;
    color: #000000;
    border: 1px solid #cccccc;
    transition: ease-in-out 300ms;
}

.button:hover{
    background-color: #ccc;
}

span {
    font-size: 12px;
}

span a {
    text-decoration: none;
    color: #000000;
}
