
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }

  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
  }

  .statusChangeTitle {
    margin-bottom: 20px;
  }

  select {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
  }

  .buttons-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .status-modal-button{
    padding: 10px 30px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 12px;
    cursor: pointer;
    transition: 300ms ease-in-out;
  }

  .status-modal-button-save{
    padding: 10px 30px;
    background-color: #000000;
    border: 1px solid #000000;
    color: #fff;
    border-radius: 12px;
    cursor: pointer;
    transition: 300ms ease-in-out;
  }