.subcategory-title{
  cursor: pointer;
}

.content {
    max-height: 0;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
}

.content.show{
  height: auto;
}

.price-form{
  display: flex;
  padding : 20px 0;
}

.price-input{
  border: 1px solid #043528;
  background-color: #FFFCE5;
  padding: 5px;
  border-radius: 4px;
  margin-right: 20px;
  min-width: 200px;
}

.save-button{
  border: 1px solid #043528;
  background-color: #043528;
  color: #FFFCE5;
  border-radius: 4px;
  padding: 0 20px;
  cursor: pointer;
}
