.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }

  .modal-content {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 2rem;
    border-radius: 5px;
    width: 400px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  }
  .close-modal-button {
    padding: 0.5rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-bottom: 10px;
  }
