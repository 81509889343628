.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    background: rgba(0, 0, 0, 0.42);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
  }

  .modal-content {
    width: 40vw;
    min-height: 35vh;
    background-color: #fff;
    padding: 30px;
    border-radius: 20px;
  }
  .buttons-wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .modal-button {
    padding: 8px 20px;
    margin: 10px;
    border: none;
    border-radius: 20px;
    font-size: 14px;
    cursor: pointer;
  }

  .modal-button-save {
    background-color: #000000;
    color: #fff;
    border: 1px solid #000000;
  }

  .modal-button-cancel {
    background-color: #ffffff;
    color: #000000;
    border: 1px solid #cccccc
  }
  .modal-textarea {
    width: 100%;
    min-height: 200px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    resize: vertical;
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .modal-textarea:focus{
    outline:none;
    border: 1px solid #ccc;

 }

  .title-button-wrapper{
    display: flex;
    justify-content: space-between;
  }

  .close-button {
    font-size: 24px;
    cursor: pointer;
  }

  @media (max-width: 600px) {
    .modal-content{
      width: 90vw;
    }
  }