.templateItem {
  max-width: 562px;
  min-width: 562px;
  display: flex;
  flex: 1 1;
  align-items: center;
  cursor: pointer;
  padding: 5px;
  border-radius: 10px;
  font-size: 20px;
  color: #043528;
  text-decoration: none;
  box-sizing: border-box;
  border: 1px solid #043528;
  background-color: #ffffff;
  transition: 0.3s;

  a {
    text-decoration: none;
  }

  &.client-name {
    font-size: 22px;
    text-align: center;
  }

  &:hover {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
  }

  .informationIcon {
    width: 32px;
    height: 32px;
    margin-right: 10px;
  }
}
