// Reset margin and padding
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Calibri", Arial, sans-serif;
  font-size: 16px;
  line-height: 1.5;
}

@font-face {
  font-family: "Stawford";
  src: url("../public/fonts/Strawford-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Stawford", fallback, sans-serif;
}

.container {
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}

// Header styles
.stickyLogo {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1000;
  background: #fff;
}
.stickyNavbar {
  position: -webkit-sticky;
  position: sticky;
  top: 70px;
  z-index: 999;
  background: #fff;
}

// Main content styles
.main {
  padding: 30px 0;
}

// File Upload styles
.file-upload {
  margin-bottom: 30px;

  h1 {
    font-size: 24px;
    margin-bottom: 15px;
  }

  input[type="file"] {
    margin-bottom: 15px;
  }

  button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #0056b3;
    }
  }

  ul {
    margin-top: 15px;
  }

  li {
    margin-bottom: 5px;
  }
}

section {
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 15px;
}

.header-logo {
  display: flex;
  align-items: center;
  margin-right: 20px;

  .logo {
    margin-right: 20px;
  }
}

.empty-list {
  width: 100%;
  padding: 50px 0;
  text-align: center;
  color: #cccccc;
}

@media (max-width: 600px) {
  body {
    font-size: 14px;
  }

  .container {
    flex-direction: column;
    padding: 0 5px;
  }

  .file-upload {
    h1 {
      font-size: 20px;
    }

    button {
      padding: 8px 12px;
    }
  }

  .header-logo {
    flex-direction: column;
    align-items: center;
  }

  .stickyLogo {
    position: relative;
  }
  .stickyNavbar {
    position: relative;
    top: 0;
  }

  section {
    padding: 0 10px;
  }
}
