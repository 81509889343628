.formTitle {
  color: #c6e5d5;
  font-weight: 400;
  font-size: 40px;
  line-height: 60px;

  @media (max-height: 700px) {
    font-size: 30px;
    line-height: 40px;
  }
}

.form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: #c6e5d5;
}

.input-wrapper {
  display: flex;
  flex-direction: column;
  font-size: 32px;

  @media (max-height: 700px) {
    font-size: 24px;
  }
}

.input {
  padding: 0.5rem;
  border: 1px solid #c6e5d5;
  background-color: #c6e5d5;
  border-radius: 5px;
  color: #1e1e1e;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;

  @media (max-height: 700px) {
    font-size: 20px;
    padding: 0.2rem;
  }
}

.login-modal-button {
  color: #043528;
  padding: 0.5rem;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  font-size: 40px;
  background-color: #f8ed66;
  transition: background-color 0.3s ease;

  @media (max-height: 700px) {
    margin-bottom: 10px;
    font-size: 30px;
    padding: 0.4rem;
  }
}

span {
  font-size: 12px;
}

span a {
  text-decoration: none;
  color: #000000;
}
