.layout {
  display: flex;
  min-height: 100vh;

  .header,
  .footer {
    flex: none;
  }

  .contentColumn {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-left: 250px;
    width: 100%;

    .content {
      flex: 1;
    }

    @media (max-width: 1024px) {
      padding-left: 200px;
    }
  }
}
