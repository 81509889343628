.sidebar {
  position: fixed;
  height: 100vh;
  flex: 0 0 250px;
  width: 250px;
  background-color: #043528;
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 3vh;
}

.sidebarContent {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.logo_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 20px 0 20px;
  img {
    width: 100%;
  }
}

.sideBarMenu {
  display: flex;
  flex-direction: column;
  padding: 10px 0 20px 0;
}

.sideBarMenuItem {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  color: #000000;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  background-color: #c6e5d5;
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
}

.sideBarMenuItem:hover {
  background-color: #b4d8c5;
  cursor: pointer;
}

.sideBarMenuItem.active {
  background-color: #a2cab5;
}

@media (max-width: 1024px) {
  .sidebar {
    flex: 0 0 200px;
    width: 200px;
  }
}
