.searchWrapper{
    flex:1 1 50%;
}

.searchBar {
    padding: 8px 30px 8px 8px;
    border-radius: 6px;
    border: 1px solid #ccc;
    width: 100%;
}

.searchBar:focus{
    outline: none;
}

.searchContainer {
    position: relative;
    display: inline-block;
    width: 100%;
}

.searchIcon {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
}

.suggestionsContainer {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    background: white;
    z-index: 10;
}

.suggestion {
    display: block;
    padding: 8px 12px;
    text-decoration: none;
    color: black;
    border-bottom: 1px solid #f0f0f0;
}

.suggestion:hover,.suggestion.focused{
    background-color: #f0f0f0;
}
