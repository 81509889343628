.page-layout {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  align-items: center;
  height: 100vh;

  .content-container {
    display: flex;
    flex-direction: column;
    min-height: 500px;
    flex: 1 1 33%;
    justify-content: space-between;
    background-color: #043528;
    padding: 10px 42px 3vh 42px;
    height: 100%;

    .logo {
      display: flex;
      justify-content: center;
      padding: 10px 70px 0 70px;
      img {
        width: 100%;
      }
    }
  }

  .video-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 1 66%;
    height: 100vh;
    overflow: hidden;

    .background-video {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}

@media (max-width: 600px) {
  .page-layout {
    flex-direction: column;

    .content-container,
    .video-container {
      min-height: initial;
      flex: none;
    }

    .logo {
      display: none;
    }
  }
}
